.mat-form-field-type-mat-select .mat-form-field-infix {
  height: 47px;
  line-height: 2.5;
  border: 1px solid var(--bubu-select-border-grey) !important;
  padding: 4px 8px !important;
}

.mat-select-arrow {
  margin: 0 15px !important;
  padding: 8px !important;
  border: solid var(--bubu-primary) !important;
  border-width: 0 4px 4px 0 !important;
  transform: rotate(45deg) !important;
}

.mat-form-field-underline {
  display: none !important;
}

.mat-form-field-infix {
}

.mat-select-arrow-wrapper {
  padding-bottom: 8px;

}

mat-form-field {
  width: 100%;
}
