@font-face {
  font-family: 'Maax';
  src: url('assets/fonts/maax-webfont.eot');
  src: url('assets/fonts/maax-webfont.eot?#iefix') format('embedded-opentype'),
  url('assets/fonts/maax-webfont.woff2') format('woff2'),
  url('assets/fonts/maax-webfont.woff') format('woff'),
  url('assets/fonts/maax-webfont.ttf') format('truetype'),
  url('assets/fonts/maax-webfont.svg#maaxregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Maax Bold';
  src: url('assets/fonts/maax-bold-webfont.eot');
  src: url('assets/fonts/maax-bold-webfont.eot?#iefix') format('embedded-opentype'),
  url('assets/fonts/maax-bold-webfont.woff2') format('woff2'),
  url('assets/fonts/maax-bold-webfont.woff') format('woff'),
  url('assets/fonts/maax-bold-webfont.ttf') format('truetype'),
  url('assets/fonts/maax-bold-webfont.svg#maaxbold') format('svg');
  font-weight: bold;
  font-style: normal;
}
