:root {
  --bubu-red: #ff5514;
  --black: rgb(0, 0, 0);
  --bubu-red-light: #ffe5db;
  --bubu-red-dark: #c73700;
  --dark-grey: #4a4a4a !important;;
  --grey: #9b9b9b !important;;
  --mid-grey: #bcbcbc !important;;
  --light-grey: #ededed !important;;
  --teal: #009d91 !important;
  --teal-light: rgba(0, 157, 145, .75) !important;
  --teal-dark: #00645c !important;
  --white: #ffffff !important;;
  --bubu-select-border-grey: #CCCCCC;

  --bubu-error: #ff0000;
  --bubu-warning: #ff9d3f;
  --bubu-success: var(--teal);
  --bubu-success-hover: linear-gradient(180deg, var(--teal), var(--teal-dark));
  --bubu-primary: var(--bubu-red);
  --bubu-primary-hover: linear-gradient(180deg, var(--bubu-red), var(--bubu-red-dark));
  --bubu-opacity-background: rgba(0,157,147,.3);
}
